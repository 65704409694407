<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <div>
      <Breadcrumbs class="inlineblock" />
    </div>

    <v-container>
      <v-row align="center">
        Le contenu marketing ici !
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: "Marketing",
  components: {
    Breadcrumbs
  },
  data: () => {
      return {
         
      };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    ...mapGetters({
      hasAccessToBetaTestSection: 'hasAccessToBetaTestSection'
    }),
  },
  methods: {
    ...mapMutations(['showErrorMessage']),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
    }),
  },
  async mounted() {
    if (this.hasAccessToBetaTestSection) {
      this.setBreadcrumbs([{ text: this.$t("home.marketing"), href: "/marketing" }]);
    }
    else {
      this.showErrorMessage(this.$t('general.access_denied'));
      this.$router.push('/');
    }
  },
};
</script>
<style scoped>
</style> 